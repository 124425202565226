.placeholder {
    display: block;
    text-align: center;
    padding: 4rem;
    background-color: rgba(0, 0, 0, 0.01);
    border: dashed 2px #ddd;
    color: #aaa;
    font-size: 14px;
    font-style: italic;
    margin-top: 1rem;
    margin-bottom: 1rem;
}