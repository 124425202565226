dl {
    
}
 
dl dt {
    float: left; 
    
    margin-right: 10px; 
    padding: 5px;  
    width: 120px; 
}
 
dl dd {
    font-weight: bold; 
    margin: 2px 0; 
    padding: 5px 0;
}

.overallStatus {
    font-size: 32px;
    font-weight: bold;
}

.overallStatus.good {
    color: rgb(0, 75, 28);
}

.overallStatus.poor {
    color: rgb(242 173 18);
}

.overallStatus.bad {
    color: rgb(164, 38, 44);
}