.log-row.ok {
    color: #777;
}

.log-row.position.ok {
    color: #ccc;
}

.log-row.late {
    color: #fff;
    background-color: #8e8e29;
}

.log-row.not-logged {
    color: #fff;
    background-color: #8d3eb3;
}

.log-row.not-delivered {
    color: #fff;
    background-color: #b52121;
}

.log-row.highlighted {
    background-color: rgb(16, 110, 190) !important;
    color: #fff;
}

small {
    opacity: 0.35;
}

pre {
    outline: 1px solid #ccc;
    padding: 5px;
    margin: 15px;
}
.string {
    color: green;
}
.number {
    color: darkorange;
}
.boolean {
    color: blue;
}
.null {
    color: magenta;
}
.key {
    color: red;
}

.row-count {
    display: block;
    text-align: center;
    padding-bottom: 1rem;    
}
