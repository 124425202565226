.okay .ms-ProgressIndicator-progressBar {
    background-color: #009800 !important;
}

.danger .ms-ProgressIndicator-progressBar {
    background-color: #ff0000 !important;
}

.warn .ms-ProgressIndicator-progressBar {
    background-color: #ff9800 !important;
}