.badge-danger {
    color: #fff;
    background-color: #cb5050;
}

.badge-secondary {
    color: #fff;
    background-color: #6c757d;
}
.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}